import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Forms from './Forms';
import Tables from './Tables';

function index() {


  return (
    <div>
      {
        (!localStorage.getItem("admin-token-lens")) ? window.location.href = "/" :
          <>
            <Layout />
            <div className="container__wrap">

              <Route path="/forms" component={Forms} />
              <Route path="/tables" component={Tables} />

            </div></>
      }
    </div>

  )
}

export default index
