import Validator from "validatorjs";
export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.id_token;
  }
  return null;
};
export function allFieldsValidation(
  data,
  rules,
  customErrorMessages
) {
  let validation = new Validator(data, rules, customErrorMessages);
  if (validation.passes()) {
    return true;
  }
  return validation.errors;
};

export function convertTo12HourFormat(time) {
  let date;
  
  // Check if the input time is in 24-hour format
  if (/^\d{2}:\d{2}$/.test(time)) {
    const [hours, minutes] = time.split(':');
    date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
  }
  // Check if the input time is in ISO 8601 format
  else if (time.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?$/)) {
    date = new Date(time);
  }
  else {
    return "Invalid Time Format";
  }

  // Check if the date is valid
  if (isNaN(date)) {
    return "Invalid Date";
  }

  // Format the time in 12-hour format
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleString('en-US', options);
}

// Function to resize an image object to a specific width
export async function resizeImage(file, maxWidth) {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const aspectRatio = image.width / image.height;
      const newWidth = maxWidth;
      const newHeight = maxWidth / aspectRatio;

      canvas.width = newWidth;
      canvas.height = newHeight;

      context.drawImage(image, 0, 0, newWidth, newHeight);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.9);
    };

    image.src = URL.createObjectURL(file);
  });
}
