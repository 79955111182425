import React, { Component } from 'react';


const bannerImg = `${process.env.PUBLIC_URL}/img/banner.png`;

class Banner extends Component {
  render() {
    return (
      <div class="container-fluid banner_area">
        <img class="img-fluid" src={bannerImg} alt="banner" />
        <h1 class="banner_heading">Services</h1>
      </div>
    )
  }
}

export default Banner