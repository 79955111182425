import React, { Component } from 'react';


const companyLogo = `${process.env.PUBLIC_URL}/img/logo_main.png`;
class Footer extends Component {
  render() {
    return (
    <>
    <div className="container-fluid mt-4" style={{backgroundColor: 'white', margin: '0!important', padding: '0 !important'}}>
    <div className="footer" style={{backgroundColor: 'white'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md mt-3 col-12  text-white useful-links ">
          <img src="/img/landing/img/Mask Group 11.png" style={{width:'249px', paddingLeft: '23px', height: '6rem'}} />
          </div>
          <div className="col-md mt-3 col-12  text-white useful-links ">
            <h2 style={{color: 'black'}}>About Us </h2>
            <ul style={{listStyle:'none'}} className="p-0 pt-3">
              <li>
                <h4 style={{ color: 'black'}}>Our Product</h4>
              </li>
            </ul>
          </div>
          <div className="col-md mt-3 col-12  text-white useful-links">
            <h2 style={{color: 'black'}}>FAQ </h2>
            <ul style={{listStyle:'none'}} className="p-0 pt-3">
              <li>
                <h4 style={{color: 'black'}}>Report Issue</h4>
              </li>
            </ul>
          </div>
          <div className="col-md mt-3 col-12  text-white useful-links">
            <h2 style={{color: 'black'}}>Contact Us At</h2>
            <ul style={{listStyle:'none'}} className="p-0 pt-3">
              <li>
                <h4 style={{color: 'black'}}>Info@Hairapp.net</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> 
<div class="container-fluid" style={{backgroundColor: '#F2F2F2'}}>
  <div className=" row ">
  <div className="col-md-12 text-center mt-4 mb-3 ">
    <h1 className="fa fa-copyright " style={{fontSize: 19}}>2020 HAIR . All Right Reserved </h1>
  </div>
</div>
</div> 
</>
    )
  }
}

export default Footer