import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AddProductForm from './components/AddProductForm';


const FormValidation = (props) => {


    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Add Products</h3>

                </Col>
            </Row>
            <Row>
                <AddProductForm />
            </Row>
        </Container>
    );
};

export default FormValidation;
