/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Row,
  Spinner,
} from 'reactstrap';
import { useHistory } from 'react-router';
import validate from './validate';
import { allFieldsValidation } from '../../../../utils/helpers';
import api from '../../../../api';

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const HorizontalForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  props,
  data,
  blogId,
}) => {
  const [Loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setimage] = useState('');

  useEffect(() => {
    setAuthorName(data.authorName);
    setTitle(data.title);
    setDescription(data.description);
    setimage(data.image);
  }, [data]);

  async function AddImageFun(e) {
    setLoading(true);
    const formData = new FormData();
    for (const key of Object.keys(e.target.files)) {
      formData.append('images', e.target.files[key]);
    }

    const data = await api.post('admin/addPic', formData);
    setimage(data.data.data[0]);
    setLoading(false);
  }

  const [Errors, setErrors] = useState('');
  const histroy = useHistory();
  async function AddStoreAPI(e) {
    e.preventDefault();

    const apiData = {
      authorName,
      title,
      description,
      image,
    };
    const rules = {
      authorName: 'required',
      title: 'required',
      description: 'required',
      image: 'required',
    };

    const { isValid, errors } = allFieldsValidation(apiData, rules);
    if (errors) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    try {
      api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          'admin-token-lens',
        )}`;
        return config;
      });

      const result = await api.put(`admin/edit/blog/${blogId}`, {
        ...apiData,
      });

      if (result.data.success) {
        histroy.push('/tables/blogs');
      } else {
        setLoading(false);

        toast.error('Please Re - Check The Form And Try Again', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error('Server Error Please Try Again', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <>
      {Loading ? (
        <Row
          style={{
            width: '100%',
            height: '60vh',
            flex: 1,
            marginLeft: 1,
            justifyContent: 'center',
          }}
        >
          <Spinner style={{ color: '#4ce1b6' }} />
        </Row>
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Edit Blog</h5>
              </div>
              <form className="form form--horizontal" onSubmit={AddStoreAPI}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Author Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Name Of Author"
                        value={authorName}
                        onChange={e => setAuthorName(e.target.value)}
                        name="authorName"
                      />

                      {Errors ? (
                        Errors.name ? (
                          <span className="form__form-group-error">
                            {Errors.name[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Blog Title</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Name Of Author"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        name="title"
                      />

                      {Errors ? (
                        Errors.name ? (
                          <span className="form__form-group-error">
                            {Errors.name[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Author Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Name Of Author"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        name="description"
                      />

                      {Errors ? (
                        Errors.name ? (
                          <span className="form__form-group-error">
                            {Errors.name[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Add Image</span>
                  <div className="form__form-group-field">
                    {image ? (
                      <>
                        <img
                          src={image}
                          style={{
                            height: '150px',
                            width: '200px',
                            margin: '0px 15px',
                          }}
                        />
                        <span
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => setimage('')}
                        >
                          &#x2717;
                        </span>

                        {/* <span onClick={()=>setimgCollection("")}> Remove </span> */}
                      </>
                    ) : (
                      <>
                        <input type="file" onChange={e => AddImageFun(e)} />
                        {Errors ? (
                          Errors.image ? (
                            <span className="form__form-group-error">
                              {Errors.image[0]}
                            </span>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}{' '}
                      </>
                    )}
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    type="button"
                    onClick={reset}
                    disabled={pristine || submitting}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

HorizontalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'edit_blog_form', // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(HorizontalForm);
