import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Col, Container, Row, Card, CardBody, Spinner, Button
} from 'reactstrap';
import { useHistory } from 'react-router';
import Table from './VideoLinkTable';
import api from '../../../api';


const VideoLink = () => {
  const histroy = useHistory();
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const getVideoLink = () => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

      return config;
    });
    api.get('user/get/link').then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getVideoLink();
  }, []);
    return (
        <>
          <Container>
            <Row>
              <Col md={12} lg={12}>
                <Card>
                  <CardBody>
                    <div className="react-table__wrapper">
                      <h3 className="page-title">Video Links</h3>
                      <NavLink to="/forms/link">
                        <Button color="primary" type="submit">Add Link</Button>
                    </NavLink>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            {}
    
            {!Loading ? (
              <Table data={data} histroy={histroy} />
            ) : (
              <Row
                style={{
                  width: '100%',
                  height: '60vh',
                  marginLeft: 1,
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spinner style={{ color: '#4ce1b6' }} />{' '}
              </Row>
            )}
          </Container>
        </>
      );
}

export default VideoLink;