import React, { useEffect, useState } from 'react';
import axios from "axios";
import Content from '../components/content';
import { withRouter } from 'react-router-dom';

const SideBar = (props) => {
  const [categoryId, setCategoryId] = useState(0);
  const [services, setServices] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const { topService } = props;

  useEffect(() => {
    async function fetchData() {
        try {
            const data = await axios.get(`http://18.118.148.51:8000/api/service-category/`);
            setServiceCategories(data.data);
            if (topService) {
              setCategoryId(topService.category?.id);
              getServices(topService.category?.id);
            } else {
              setCategoryId(data.data[0].id);
              getServices(data.data[0].id);
            }
            }
            catch(error) {
              setServiceCategories(null);
            }
      }
    fetchData();
  }, [])

  const getServices = (id) => {
    axios
      .get(`http://18.118.148.51:8000/api/services`)
      .then((res) => {
        setServices(res.data.filter((data) => data.category.id === id));
      });
  }

  const handleClick = (id) => {
    setCategoryId(id);
    getServices(id);
  }

    return (
      <>
        <nav id="sidebar">
          <ul class="list-unstyled components mb-5">
            {
              serviceCategories.map((category, index) => (
                <li onClick={() => handleClick(serviceCategories[index].id)} class=
                {categoryId == serviceCategories[index].id
                ? "active" : ''} key={serviceCategories[index].id}>
                  <a data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                  {category.name}
                  </a>
                </li>
              ))
            }
          </ul>
        </nav>
        <Content topServices={topService} services={services} />
      </>
    )
  }

export default withRouter(SideBar);