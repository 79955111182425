import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
import axios from "axios";
import { NavLink } from 'react-router-dom';

import Button from '@mui/material/Button';

import Table from './ClientTable';
import { useHistory } from 'react-router';
import api from '../../../api';

const DataTable = () => {
    // const reactTableData = CreateTableData();
    const histroy = useHistory()
    const { t } = useTranslation('common');
    const [data, setData] = React.useState([])
    const [Loading, setLoading] = React.useState(false)
    useEffect(() => {
        setLoading(true)
        GetClients();
    }, []);

    const GetClients = () => {
        api.interceptors.request.use(function (config) {

            config.headers.Authorization = `Bearer ` + localStorage.getItem("admin-token-lens");

            return config;
        });
        api
            .get("admin/client")
            .then((res) => {
                setData(res.data.data)
                setLoading(false)

                
            });
    }
    const DeleteClient = (id) => {
        setLoading(true)
        api.interceptors.request.use(function (config) {

            config.headers.Authorization = `Bearer ` + localStorage.getItem("admin-token-lens");

            return config;
        });
        api
            .get(`admin/client/delete/${id}`)
            .then((res) => {

                if (res.data.success) {
                    setData([])
                    GetClients()

                }
            });
    }



    
    return (
        <>
            <Container>
                <Row>
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <div className="react-table__wrapper">
                                    <h3 className="page-title">Clients Reviews</h3>

                                    <NavLink to="/forms/client">
                                        <Button color="primary" type="submit">Add Review</Button>
                                    </NavLink>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <br />
                {

                }
               
                    {!Loading ? <Table data={data} DeleteClient={DeleteClient} histroy={histroy} /> :  <Row style={{ width: '100%', height: '60vh', marginLeft: 1, flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner style={{ color: '#4ce1b6' }} /> </Row>}    

            </Container>
        </>
    );
};

export default DataTable;
