import React, { useEffect } from 'react';
import {
  Col, Container, Row, Card, CardBody, Spinner,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import OrderTable from './OrderTable';
import api from '../../../api';

const contactHeaders = [
  {
    label: 'ID', key: '_id',
  },
  {
    label: 'Order_Placed_On', key: 'Order_Placed_On',
  },
  {
    label: 'Order_id', key: 'Order_id',
  },
  {
    label: 'ShippingAddress', key: 'ShippingAddress[0].Address',
  },
  {
    label: 'User', key: 'User.email',
  },
  {
    label: 'Amount', key: 'Amount',
  },
];

const DataTable = () => {
  const [filter, setfilter] = React.useState([]);
  const [data, setData] = React.useState([]);
  const csvLink = {
    headers: contactHeaders,
    data,
    filename: 'csvfile.csv',
  };
  useEffect(() => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

      return config;
    });
    api
      .get('admin/Orders')
      .then((res) => {
        setData(res.data.data);
        setfilter(res.data.data);
      });
  }, []);


  function handleSearch(val) {
    if (val == '') {
      setfilter(data);
      return;
    }

    const newData = data.filter((e) => {
      if (e.Order_id.includes(val)) {
        return e;
      }
    });
    setfilter(newData);
  }

  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <h3 className="page-title">Orders List </h3>
                <CSVLink {...csvLink}>Export to CSV</CSVLink>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>
      <br />
      <Row style={{
        width: '100%', marginLeft: 1, flex: 1, justifyContent: 'center', alignItems: 'center',
      }}
      >
        {data.length > 0 ? <OrderTable handleSearch={handleSearch} data={filter} />
          : <Spinner style={{ color: '#4ce1b6' }} />}
      </Row>

    </Container>
  );
};

export default DataTable;
