import React, { useEffect, useState } from 'react';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar,
} from 'reactstrap';
import { toast } from 'react-toastify';
import axios from '../../../containers/axios';

const ToolbarOptions = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'image', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

const TextEditorTwo = (({
  onChange, data, id, isButtonShow = true,
}) => {
  // console.log("data", id);


  const html = data;
  // eslint-disable-next-line max-len
  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(html))));


  const onEditorStateChange = (items) => {
    // console.log(items.getCurrentContent())
    setEditorState(items);
    if (onChange) {
      // alert("hello")
      onChange(draftToHtml(convertToRaw(items.getCurrentContent())));
      // console.log("editorState", draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };

  const HandleSubmit = () => {
    let url = '';
    if (id === '2') {
      url = 'privacy/';
    } else {
      url = 'terms/';
    }
    axios
      .post(url, {
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      })
      // headers: {
      //   authorization: "Token " + token,
      // },
      // })
      .then(async (res) => {
        console.log('RESPONSE', res.data.status);
        if (res.data.status === 1) {
          toast(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (res.data.status === 0) {
          toast(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log('ERROR', err);
      });
  };


  return (
    <div className="text-editor">
      <Editor
        editorState={editorState || data}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={ToolbarOptions}
      />
      {isButtonShow && (
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit" onClick={HandleSubmit}>Submit</Button>
        </ButtonToolbar>
      )}
    </div>
  );
});

TextEditorTwo.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextEditorTwo;
