import axios from "axios";

const api = axios.create({
  // baseURL: "https://backend.frameskraft.com/api/",
  // baseURL: 'http://localhost:8000/api/',
  baseURL: 'https://frameskraft.com:8000/api/'
  // baseURL: 'https://node-frames-backend.herokuapp.com/api/',
  // baseURL: 'http://lensbackend-env.eba-hynp4ube.us-east-2.elasticbeanstalk.com/api/',
});
//  api.defaults.withCredentials = true;

// api.interceptors.response.use(function (response) {
//     return response.dajta;
// }, function (error) {
//     return Promise.reject(error);
// });

export default api;
