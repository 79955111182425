import React, { useEffect } from 'react';
import {
  Col, Container, Row, Card, CardBody, Spinner,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import Table from './BlogTable';
import api from '../../../api';

const blogHeaders = [
  {
    label: 'ID', key: '_id',
  },
  {
    label: 'Title', key: 'title',
  },
  {
    label: 'Image', key: 'image',
  },
  {
    label: 'Author Name', key: 'authorName',
  },
  {
    label: 'Description', key: 'description',
  },
  {
    label: 'Created At', key: 'createdAt',
  },
];

const DataTable = () => {
  const histroy = useHistory();
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const csvLink = {
    headers: blogHeaders,
    data,
    filename: 'csvfile.csv',
  };

  const getBlogs = () => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

      return config;
    });
    api.get('user/get/blogs').then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getBlogs();
  }, []);

  const deleteBlogs = (id) => {
    setLoading(true);
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

      return config;
    });
    api
      .delete(`admin/delete/blog/${id}`)
      .then((res) => {
        if (res.data.success) {
          setData([]);
          getBlogs();
        }
      });
  };


  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="react-table__wrapper">
                  <h3 className="page-title">Blogs</h3>
                  <div>
                    <CSVLink {...csvLink}>Export to CSV</CSVLink>
                    <NavLink to="/forms/blogs">
                      <Button color="primary" type="submit">Add Blogs</Button>
                    </NavLink>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        {}

        {!Loading ? (
          <Table data={data} histroy={histroy} deleteBlogs={deleteBlogs} />
        ) : (
          <Row
            style={{
              width: '100%',
              height: '60vh',
              marginLeft: 1,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner style={{ color: '#4ce1b6' }} />{' '}
          </Row>
        )}
      </Container>
    </>
  );
};

export default DataTable;
