/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Row,
  Spinner,
} from 'reactstrap';
import { useHistory } from 'react-router';
import validate from './validate';
import { allFieldsValidation } from '../../../../utils/helpers';
import api from '../../../../api';

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const HorizontalForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  props,
  data,
  orderId,
}) => {
  const [Loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');

  useEffect(() => {
    setOrderStatus(data[0].orderStatus);
  }, [data]);

  const [Errors, setErrors] = useState('');
  const histroy = useHistory();
  async function AddStoreAPI(e) {
    e.preventDefault();

    const apiData = {
      orderStatus,
    };
    const rules = {
      orderStatus: 'required',
    };

    const { isValid, errors } = allFieldsValidation(apiData, rules);
    if (errors) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    try {
      api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          'admin-token-lens',
        )}`;
        return config;
      });

      const result = await api.put(`admin/update/order/${orderId}`, {
        ...apiData,
      });

      if (result.data.success) {
        histroy.push('/tables/orders');
      } else {
        setLoading(false);

        toast.error('Please Re - Check The Form And Try Again', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error('Server Error Please Try Again', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <>
      {Loading ? (
        <Row
          style={{
            width: '100%',
            height: '60vh',
            flex: 1,
            marginLeft: 1,
            justifyContent: 'center',
          }}
        >
          <Spinner style={{ color: '#4ce1b6' }} />
        </Row>
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Edit Order</h5>
              </div>
              <form className="form form--horizontal" onSubmit={AddStoreAPI}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Order Status</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => setOrderStatus(e.target.value)}
                        value={orderStatus}
                        aria-label="Default select example"
                      >
                        <option value="" selected>
                          Select Order Status
                        </option>
                        <option value="Order Placed">Order Placed</option>
                        <option value="Lens Fitted">Lens Fitted</option>
                        <option value="Quality Checked">Quality Checked</option>
                        <option value="Dispatched">Dispatched</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                      {Errors ? (
                        Errors.orderStatus ? (
                          <span className="form__form-group-error">
                            {Errors.orderStatus[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    type="button"
                    onClick={reset}
                    disabled={pristine || submitting}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

HorizontalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'edit_order_form', // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(HorizontalForm);
