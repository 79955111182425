import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { SweetAlert } from "sweetalert2/dist/sweetalert2.js";

// import 'sweetalert2/src/sweetalert2.scss'

export default function BasicTable({ data, DeleteBanner, histroy }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S. No</TableCell>
            {/* <TableCell align="right">Image</TableCell> */}
            <TableCell align="right">Name</TableCell>

            <TableCell align="right">Image</TableCell>
            <TableCell align="right">Edit</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
            data.map((row, index) => {
              return (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>

                  <TableCell align="right">
                    {" "}
                    <img
                      src={row.image}
                      height="20px"
                      style={{ width: "100px" }}
                      width="20px"
                    />
                  </TableCell>
                  <TableCell align="right"><Button variant="contained" onClick={ ()=>histroy.push(`/forms/edit/banner/${row._id}`) }>Edit</Button></TableCell>

                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => DeleteBanner(row._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
