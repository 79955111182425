import React, { useState } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import axios from "axios";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { allFieldsValidation } from "./../../../../utils/helpers";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";

import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Row,
  Spinner,
} from "reactstrap";

import validate from "./validate";
import { useHistory } from "react-router";
import moment from "moment";
import api from "../../../../api";

let game = "dddd";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["red", "green", "blue", "orange", "yellow"];

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: "",
  meta: null,
  type: "text",
};

const HorizontalForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  props,
}) => {
  const [color, setcolor] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setcolor(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [Loading, setLoading] = useState(false);
  const [name, setname] = useState("");
  
  const [image, setimage] = useState("");

  const [Errors, setErrors] = useState("");
  const histroy = useHistory();
  async function AddImageFun(e) {
    setLoading(true);
    let formData = new FormData();
    for (const key of Object.keys(e.target.files)) {
      formData.append("images", e.target.files[key]);
    }

    var data = await api.post("admin/addPic", formData);
    setimage(data.data.data[0]);
    setLoading(false);
  }
  async function AddStoreAPI(e) {
    e.preventDefault();

    let data = {
      name,
    
      image,
    };
    const rules = {
      name: "required",
    
      image: "required",
    };

    const { isValid, errors } = allFieldsValidation(data, rules);
    if (errors) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    try {
      api.interceptors.request.use(function (config) {
        config.headers.Authorization =
          `Bearer ` + localStorage.getItem("admin-token-lens");
        return config;
      });

      let result = await api.post("admin/add/Banners", {
        ...data,
      });

      if (result.data.success) {
        histroy.push("/tables/banners");
      } else {
        setLoading(false);

        toast.error("Please Re - Check The Form And Try Again", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error("Server Error Please Try Again", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <>
      {Loading ? (
        <Row
          style={{
            width: "100%",
            height: "60vh",
            flex: 1,
            marginLeft: 1,
            justifyContent: "center",
          }}
        >
          <Spinner style={{ color: "#4ce1b6" }} />
        </Row>
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Add New Banner</h5>
                <h5 className="subhead"></h5>
              </div>
              <form className="form form--horizontal" onSubmit={AddStoreAPI}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Name Of The Client"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        name="name"
                      />

                      {Errors ? (
                        Errors.name ? (
                          <span className="form__form-group-error">
                            {Errors.name[0]}
                          </span>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
               
                <div className="form__form-group">
                  <span className="form__form-group-label">Add Image</span>
                  <div className="form__form-group-field">
                    {image ? (
                      <>
                        <img
                          src={image}
                          style={{
                            height: "150px",
                            width: "200px",
                            margin: "0px 15px",
                          }}
                        />
                        <span
                          style={{
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => setimage("")}
                        >
                          &#x2717;
                        </span>

                        {/* <span onClick={()=>setimgCollection("")}> Remove </span> */}
                      </>
                    ) : (
                      <>
                        <input type="file" onChange={(e) => AddImageFun(e)} />
                        {Errors ? (
                          Errors.image ? (
                            <span className="form__form-group-error">
                              {Errors.image[0]}
                            </span>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}{" "}
                      </>
                    )}
                  </div>
                </div>
             
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    type="button"
                    onClick={reset}
                    disabled={pristine || submitting}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

HorizontalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: "subscription_form_validation", // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(HorizontalForm);
