import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Order from '../../../Tables/DataTable/orders';
import Contact from '../../../Tables/DataTable/contacts';
import Store from '../../../Tables/DataTable/Store';

import Products from '../../../Tables/DataTable/Products';
import Clients from '../../../Tables/DataTable/Clients';
import Users from '../../../Tables/DataTable/Users';
import NewsLetter from '../../../Tables/DataTable/NewsLetter';
import JoinTeams from '../../../Tables/DataTable/JoinTeams';
import Banners from '../../../Tables/DataTable/Banners';
import Blogs from '../../../Tables/DataTable/Blogs';
import Faq from '../../../Tables/DataTable/Faq';
import Sale from '../../../Tables/DataTable/Sale';
import Comment from '../../../Tables/DataTable/Comment';
import Prescription from '../../../Tables/DataTable/Prescription';
import GuestUser from '../../../Tables/DataTable/GuestUser';
import VideoLink from '../../../Tables/DataTable/VideoLink';

export default () => (
  <Switch>
    <Route path="/tables/orders" component={Order} />
    <Route path="/tables/contacts" component={Contact} />
    <Route path="/tables/stores" component={Store} />
    <Route path="/tables/services" component={Products} />
    <Route path="/tables/clients" component={Clients} />
    <Route path="/tables/users" component={Users} />
    <Route path="/tables/NewsLetter" component={NewsLetter} />
    <Route path="/tables/JoinTeams" component={JoinTeams} />
    <Route path="/tables/Banners" component={Banners} />
    <Route path="/tables/blogs" component={Blogs} />
    <Route path="/tables/faq" component={Faq} />
    <Route path="/tables/sale" component={Sale} />
    <Route path="/tables/comment" component={Comment} />
    <Route path="/tables/prescription" component={Prescription} />
    <Route path="/tables/guest" component={GuestUser} />
    <Route path="/tables/link" component={VideoLink} />
  </Switch>
);
