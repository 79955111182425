import React, { Component } from 'react';

const companyLogo = `${process.env.PUBLIC_URL}/img/logo_main.png`;

class NavBar extends Component {
  
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light navbar_pad ">
    <button className="navbar-toggler ml-auto mt-5 mr-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="col-md-3">
      <img src="/img/landing/img/Mask Group 11.png" className="home-logo" />
    </div>
    <div className="col-md-9 " style={{paddingRight: '6rem'}}>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item active top_head ml-5">
            <a className="nav-link" style={{paddingLeft: 60}} href="/"><span>Home</span></a>
          </li>
          <li className="nav-item active top_head ml-5">
            <a className="nav-link" style={{paddingLeft: 60}} href="/#aboutUs"><span>About Us</span></a>
          </li>
          <li className="nav-item my-2 my-lg-0 active top_head ml-5">
            <a className="nav-link" style={{paddingLeft: 60}} href="/selectOption"><span>Our Services</span></a>
          </li>
          <li className="nav-item my-2 my-lg-0 active top_head ml-5">
            <a className="nav-link" style={{paddingLeft: 60}} href="/#contactUs"><span>Contact Us</span></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
    )
  }
}

export default NavBar