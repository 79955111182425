import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LinkForm from './components/LinkForm';

const AddLink = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Link</h3>
      </Col>
    </Row>
    <Row>
      <LinkForm />
    </Row>
  </Container>
);

export default AddLink;
