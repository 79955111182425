import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './logic.css'
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Row, Spinner } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';

const Landing = () => {

  const history = useHistory()

  const [email, setEmail] = useState("")
  const [Loading, setLoading] = useState("")
  const [password, setpassword] = useState("")
  async function SubmitForm() {

    if (email == "" || password == "") {
      toast.error('Please Fill All Inputs!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setLoading(true)

    var data = await api.post("admin/login", { email, password })

    if (data.data.user) {
       history.push(`verify/${data.data.user._id}`)
    } else {
      setLoading(false)

      toast.error(data.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }
  return <>
    {
      Loading ? <Row style={{ width: '100%', height: '100vh', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Spinner style={{ color: '#4ce1b6' }} />
      </Row> :

        <div class="homePage" >
          <h1>LOGIN</h1>
          <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
          <input type="password" name="pasword" value={password} onChange={(e) => setpassword(e.target.value)} placeholder="Password" />
          <button class="submit" onClick={() => SubmitForm()}   >Login</button>
        </div>
    }
  </>

};


export default Landing;
