import React, { useEffect } from 'react';
import {
  Col, Container, Row, Card, CardBody, Spinner,
} from 'reactstrap';
import { useHistory } from 'react-router';
import Table from './PrescriptionTable';
import api from '../../../api';

const DataTable = () => {
  const histroy = useHistory();
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const getPrescriptions = () => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

      return config;
    });
    api.get('user/get/prescriptions').then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getPrescriptions();
  }, []);


  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="react-table__wrapper">
                  <h3 className="page-title">Comment</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        {}

        {!Loading ? (
          <Table data={data} histroy={histroy} />
        ) : (
          <Row
            style={{
              width: '100%',
              height: '60vh',
              marginLeft: 1,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner style={{ color: '#4ce1b6' }} />{' '}
          </Row>
        )}
      </Container>
    </>
  );
};

export default DataTable;
