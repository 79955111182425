import React, { useEffect } from 'react';
import {
  Col, Container, Row, Spinner,
} from 'reactstrap';
import EditProduct from './components/EditProduct';
import api from '../../../api';

const EditStore = (props) => {
  const [data, setData] = React.useState([]);
  useEffect(() => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

      return config;
    });

    api
      .get(
        `admin/get/lens/${props.match.params.id}`,
      )
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Edit Product</h3>
        </Col>
      </Row>
      <Row
        style={{
          width: '100%',
          height: '60vh',
          flex: 1,
          marginLeft: 1,
          justifyContent: 'center',
        }}
      >
        {data.length > 0 ? (
          <EditProduct data={data[0]} LensId={props.match.params.id} />
        ) : (
          <Spinner style={{ color: '#4ce1b6' }} />
        )}{' '}
      </Row>
    </Container>
  );
};

export default EditStore;
