/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

function BasicTable({ data, deleteSale }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S. No</TableCell>
            <TableCell align="right">Product Type</TableCell>
            <TableCell align="right">Category</TableCell>
            <TableCell align="right">Brand</TableCell>
            <TableCell align="right">Sale Price</TableCell>
            <TableCell align="right">Sale End</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0
            && data.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">{row.productType}</TableCell>
                <TableCell align="right">{row.category}</TableCell>
                <TableCell align="right">{row.brand}</TableCell>
                <TableCell align="right">{row.salePrice}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => deleteSale(row._id)}
                  >
                    End Sale
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

BasicTable.propTypes = {
  deleteSale: PropTypes.func,
};

export default BasicTable;
