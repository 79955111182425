import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import Landing from '../../Landing/index';
import Verify from '../../Landing/Verify';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import LogInPhoto from '../../Account/LogInPhoto/index';
import Register from '../../Account/Register/index';
import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import { withRouter } from 'react-router-dom';
import BookNow from '../../Landing/booknow';
import Services from '../../Landing/Services';
import SelectOption from '../../SelectOptionPage/pages/home'

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/verify/:id" component={Verify} />
        <Route path="/" component={WrappedRoutes} />
        
      </Switch>
    </main>
  </MainWrapper>
);

export default withRouter(Router);
