import React from "react";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import { useHistory } from "react-router";

const SidebarContent = ({
  onClick,
}) => {
  const hideSidebar = () => {
    onClick();
  };
  const histroy = useHistory();
  const Logout = () => {
    localStorage.removeItem("admin-token-lens");
    histroy.push("/");
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          icon="user"
          title="Products"
          route="/tables/services"
          onClick={hideSidebar}
        />

        <SidebarLink
          icon="chart-bars"
          title="Orders"
          route="/tables/orders"
          onClick={hideSidebar}
        />

        <SidebarLink
          icon="store"
          title="Stores"
          route="/tables/stores"
          onClick={hideSidebar}
        />

        <SidebarLink
          icon="user"
          title="Contacts Us"
          route="/tables/contacts"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Clients"
          route="/tables/clients"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Users"
          route="/tables/users"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="NewsLetter"
          route="/tables/NewsLetter"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="JoinTeams"
          route="/tables/JoinTeams"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Banners"
          route="/tables/Banners"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Blogs"
          route="/tables/blogs"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="FAQ"
          route="/tables/faq"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Sale"
          route="/tables/sale"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Comment"
          route="/tables/comment"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Prescription"
          route="/tables/prescription"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Guest User"
          route="/tables/guest"
          onClick={hideSidebar}
        />
        <SidebarLink
          icon="user"
          title="Video Link"
          route="/tables/link"
          onClick={hideSidebar}
        />
      </ul>
      <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" onClick={Logout} />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
