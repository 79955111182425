import React from 'react';
import { Route, Switch } from 'react-router-dom';

import addStore from '../../../Form/FormValidation/addStore';
import addClient from '../../../Form/FormValidation/addClient';
import AddBanner from '../../../Form/FormValidation/AddBanner';
import editStore from '../../../Form/FormValidation/editStore';
import EditClient from '../../../Form/FormValidation/EditClient';
import EditProduct from '../../../Form/FormValidation/EditProduct';
import Subscription from '../../../Form/FormValidation/subscription';
import EditBanner from '../../../Form/FormValidation/EditBanner';
import EditBlog from '../../../Form/FormValidation/EditBlog';
import AddBlogs from '../../../Form/FormValidation/AddBlogs';
import AddFaq from '../../../Form/FormValidation/AddFaq';
import AddSale from '../../../Form/FormValidation/AddSale';
import AddLink from '../../../Form/FormValidation/AddLink';
import EditOrder from '../../../Form/FormValidation/EditOrder';


export default () => (
  <Switch>
    <Route path="/forms/services" component={Subscription} />
    <Route path="/forms/store" component={addStore} />
    <Route path="/forms/client" component={addClient} />
    <Route path="/forms/banner" component={AddBanner} />
    <Route path="/forms/blogs" component={AddBlogs} />
    <Route path="/forms/faq" component={AddFaq} />
    <Route path="/forms/sale" component={AddSale} />
    <Route path="/forms/link" component={AddLink} />
    <Route path="/forms/edit/store/:id" component={editStore} />
    <Route path="/forms/edit/product/:id" component={EditProduct} />
    <Route path="/forms/edit/client/:id" component={EditClient} />
    <Route path="/forms/edit/banner/:id" component={EditBanner} />
    <Route path="/forms/edit/blog/:id" component={EditBlog} />
    <Route path="/forms/edit/order/:id" component={EditOrder} />
  </Switch>
);
