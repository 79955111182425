import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import FaqForm from './components/FaqForm';

const AddFaq = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Faq</h3>

      </Col>
    </Row>
    <Row>
      <FaqForm />
    </Row>
  </Container>
);

export default AddFaq;
