import {
  ADD_TO_BOOKING,
} from '../actions/bookingActions';

const initialState = {
  items: [],
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_BOOKING: {
      console.log('check action value ::', action);
      state.items = action.payload;
      return { ...state };
    }
    default:
      return state;
  }
};

export default bookingReducer;
