import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addToBooking } from '../../../redux/actions/bookingActions';

const Content = (props) => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkedValues, setCheckedValues] = useState([])
  const handleBooking = () => {
    props.history.push('/book-now');
    props.addToBooking(checkedList);
  }
  useEffect(() => {
    if(props.topServices) {
      checkedList.push(props.topServices);
      checkedValues.push(props.topServices?.id);
    }
  }, []);
  const handleChange = (e, item) => {
    if (e.target.checked) {
      checkedList.push(item);
      checkedValues.push(item.id);
    } else {
      checkedList.pop(item);
      checkedValues.pop(item.id);
    }
    setCheckedValues([...checkedValues]);
    setCheckedList([...checkedList])
  }
    return (
      <div id="content" className="p-4 p-md-5">
        {
          props.services && props.services.map((pack, i) => (
            <div id="box_bg" key={props.services[i].id}>
              <label>
                <div key={props.services[i].id} className="row">
                  <div className="col-md-9 offer_text pr-4">
                    <p className="mb-2">{pack.name}</p>
                    <p className="mb-0">{pack.description}</p>
                  </div>
                  <div className="col-md-2 offer_price">
                    <p className="mb-2">${pack.price}</p>
                    {pack.specialOffer
                    && (
                    <p className="mb-0">Special Offer
                      <span className="offer_icon">
                        <svg
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 256.313 256.313"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path d="M163.854,250.072c-8.365,8.322-21.87,8.322-30.192,0L16.047,139.762
                              c-6.576-6.168-14.414-17.856-14.979-26.853c-1.512-24.34-0.446-70.947,0.294-95.657C1.628,8.246,9.2,0.696,18.212,0.489
                              c31.568-0.734,98.452-1.518,104.669,4.705l129.52,117.179c8.316,8.322,0.772,29.371-7.609,37.736L163.854,250.072z M60.789,36.284
                              c-7.054-7.038-18.46-7.038-25.52,0c-7.038,7.06-7.038,18.46,0,25.498c7.065,7.044,18.471,7.044,25.52,0
                              C67.833,54.744,67.833,43.338,60.789,36.284z"
                            />
                          </g>
                        </svg>
                      </span>
                    </p>
                    )}
                  </div>
                  <div key={props.services[i].id} className="col-md-1 offer_check">
                    <input checked={checkedValues.includes(props.services[i].id)}
                    id={props.services[i].id} type="checkbox" onChange={(e) => handleChange(e, props.services[i])} />
                  </div>
                </div>
              </label>
            </div>
          ))
        }
        <div className="submit_btn">
          <button onClick={() => handleBooking()}>Book Now
            <span>
              <svg enableBackground="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z" /></svg>
            </span>
          </button>
        </div>
      </div>
    );
  }

const mapDispatchToProps = { addToBooking };

export default withRouter(connect(null, mapDispatchToProps)(Content));
