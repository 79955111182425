import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import TablePagination from '@mui/material/TablePagination';
import useTablePaginator from '../hooks/useTablePaginator';

export default function BasicTable({ data = [] }) {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePaginator();
 return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S. No</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Phone Number</TableCell>
            <TableCell align="right">City</TableCell>
            <TableCell align="right">isFranchise</TableCell>
            <TableCell align="right">Property Type</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 && data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row , index) => {
         return  <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              {/* <TableCell align="right">  <img src={row.imgCollection[0]} height="50px"  width="50px" /></TableCell> */}
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.phone_number}</TableCell>
              <TableCell align="right">{row.city}</TableCell>
              <TableCell align="right">{row?.isFranchise ? 'Yes' : 'No'}</TableCell>
              <TableCell align="right">{row?.propertyType || ''}</TableCell>
              <TableCell align="right">
              {moment(row.createdAt).format('MM/DD/YYYY')}
              </TableCell>
               </TableRow>
})}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}