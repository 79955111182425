import React, { useEffect } from 'react';
import {
  Col, Container, Row, Card, CardBody, Spinner,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import ShopTables from './shopTables';
import api from '../../../api';

const storeHeaders = [
  {
    label: 'ID', key: '_id',
  },
  {
    label: 'Shop', key: 'name',
  },
  {
    label: 'Address', key: 'address',
  },
  {
    label: 'Phone Number', key: 'phone_number',
  },
  {
    label: 'Open Time', key: 'open_time',
  },
  {
    label: 'Close Time', key: 'close_time',
  },
];

const DataTable = () => {
  const histroy = useHistory();
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const csvLink = {
    headers: storeHeaders,
    data,
    filename: 'csvfile.csv',
  };

  useEffect(() => {
    setLoading(true);
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'admin-token-lens',
      )}`;

      return config;
    });
    api.get('admin/view/Store').then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  }, []);

  const DeleteStore = (id) => {
    setLoading(true);
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'admin-token-lens',
      )}`;

      return config;
    });
    api.get(`admin/delete/Store/${id}`).then((res) => {
      if (res.data.success) {
        setData([]);
        api.get('admin/view/Store').then((res) => {
          setData(res.data.data);
          setLoading(false);
        });
      }
    });
  };

  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <h3 className="page-title">Stores</h3>
                <div>
                  <CSVLink {...csvLink}>Export to CSV</CSVLink>
                  <NavLink to="/forms/store">
                    <Button color="primary" type="submit">
                      Add Store
                    </Button>
                  </NavLink>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      {!Loading ? (
        <ShopTables histroy={histroy} data={data} DeleteStore={DeleteStore} />
      ) : (
        <Row
          style={{
            width: '100%',
            height: '60vh',
            flex: 1,
            marginLeft: 1,
            justifyContent: 'center',
          }}
        >
          {' '}
          <Spinner style={{ color: '#4ce1b6' }} />
        </Row>
      )}{' '}
    </Container>
  );
};

export default DataTable;
