import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AddClientForm from './components/AddClientForm';


const FormValidation = (props) => {

    
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Add Client Review</h3>

                </Col>
            </Row>
            <Row>
                <AddClientForm />
            </Row>
        </Container>
    );
};

export default FormValidation;
