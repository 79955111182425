import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './logic.css'
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Row, Spinner } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';

const Verify = (props) => {

    const history = useHistory()

    const [otp, setotp] = useState("")
    const [Loading, setLoading] = useState("")
    async function SubmitForm() {

        if (otp == "") {
            toast.error('Please Fill the Input!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setLoading(true)

        var data = await api.post("admin/verify", { otp: otp, id: props.match.params.id })

        if (data.data.token) {
            localStorage.setItem("admin-token-lens", data.data.token)
            history.push("/tables/services")
        } else {
            setLoading(false)

            toast.error(data.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    return <>
        {
            Loading ? <Row style={{ width: '100%', height: '100vh', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Spinner style={{ color: '#4ce1b6' }} />
            </Row> :

                <div class="homePage" >
                    <h1>Verify OTP</h1>
                    <input type="text" name="otp" value={otp} onChange={(e) => setotp(e.target.value)} placeholder="otp" />

                    <button class="submit" onClick={() => SubmitForm()}   >Verify</button>
                </div>
        }
    </>

};


export default Verify;
