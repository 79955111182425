import React, { useEffect, useState } from 'react';
import {
  Col, Container, Row, Card, CardBody, Spinner,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import StoreTable from './StoreTable';
import api from '../../../api';

const contactHeaders = [
  {
    label: 'ID', key: '_id',
  },
  {
    label: 'Name', key: 'name',
  },
  {
    label: 'City', key: 'city',
  },
  {
    label: 'Phone Number', key: 'phone_number',
  },
  {
    label: 'Email', key: 'email',
  },
  {
    label: 'isFranchise', key: 'isFranchise',
  },
  {
    label: 'Property Type', key: 'propertyType',
  },
];

const DataTable = () => {
  const [isFranchise, setIsFranchise] = useState('');
  const [data, setData] = React.useState([]);

  const csvLink = {
    headers: contactHeaders,
    data,
    filename: 'csvfile.csv',
  };

  useEffect(() => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;
      return config;
    });

    api
      .get('admin/contact-us')
      .then((res) => {
        const contactsData = res.data.data;
        if (isFranchise === 'true' && contactsData.length) {
          setData([...contactsData.filter((d) => d.isFranchise)]);
        } else if (isFranchise === 'false' && contactsData.length) {
          setData([...contactsData.filter((d) => !d.isFranchise)]);
        } else {
          setData([...contactsData]);
        }
      });
  }, [isFranchise]);
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <h3 className="page-title">Conatct - Us </h3>
                <CSVLink {...csvLink}>Export to CSV</CSVLink>
              </div>
              <div>
                  <label>Franchise: </label>
                  <select name="isFranchise" id="isFranchise" onChange={(event) => setIsFranchise(event.target.value)}>
                    <option defaultValue=""></option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>
      <br />
      {data.length > 0 ? <StoreTable data={data} /> : (
        <Row style={{
          width: '100%', height: '60vh', marginLeft: 1, flex: 1, justifyContent: 'center',
        }}
        >
           <Spinner style={{ color: '#4ce1b6' }} />
        </Row>
      )}

    </Container>
  );
};

export default DataTable;
