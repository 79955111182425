import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SaleForm from './components/SaleForm';

const AddSale = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Sale</h3>

      </Col>
    </Row>
    <Row>
      <SaleForm />
    </Row>
  </Container>
);

export default AddSale;
