import React, { useEffect } from 'react';
import {
  Col, Container, Row, Card, CardBody, Spinner,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import Table from './Table';
import api from '../../../api';

const productHeaders = [
  {
    label: 'ID', key: '_id',
  },
  {
    label: 'Name', key: 'name',
  },
  {
    label: 'Shape', key: 'shape',
  },
  {
    label: 'Color', key: 'color',
  },
  {
    label: 'Brand', key: 'brand',
  },
  {
    label: 'Material', key: 'material',
  },
  {
    label: 'Images', key: 'imgCollection',
  },
  {
    label: 'Discounted Price', key: 'discount_price',
  },
  {
    label: 'Price', key: 'price',
  },
  {
    label: 'Technical Information', key: 'technical_infornation',
  },
  {
    label: 'Gender', key: 'gender',
  },
  {
    label: 'Quantity', key: 'quantity',
  },
  {
    label: 'Category', key: 'category',
  },
  {
    label: 'Disposables', key: 'disposables',
  },
  {
    label: 'isTrending', key: 'isTrending',
  },
  {
    label: 'Created At', key: 'createdAt',
  },
];

const DataTable = () => {
  const histroy = useHistory();
  const [data, setData] = React.useState([]);
  const [filter, setfilter] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const csvLink = {
    headers: productHeaders,
    data,
    filename: 'csvfile.csv',
  };

  useEffect(() => {
    setLoading(true);
    subscriptiondata();
  }, []);

  const subscriptiondata = () => {
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'admin-token-lens',
      )}`;

      return config;
    });
    api.get('admin/view/lens').then((res) => {
      setData(res.data.data);
      setfilter(res.data.data);
      setLoading(false);

      console.log(res.data, 'res');
    });
  };
  const DeleteLens = (id) => {
    setLoading(true);
    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'admin-token-lens',
      )}`;

      return config;
    });
    api.get(`admin/delete/lens/${id}`).then((res) => {
      if (res.data.success) {
        setData([]);
        setfilter([]);
        subscriptiondata();
      }
    });
  };

  function handleSearch(val) {
    if (val == '') {
      setfilter(data);
      return;
    }

    const newData = data.filter((e) => {
      if (e._id.includes(val)) {
        return e;
      }
    });
    setfilter(newData);
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="react-table__wrapper">
                  <h3 className="page-title">Product List</h3>
                  <div>
                    <CSVLink {...csvLink}>Export to CSV</CSVLink>
                    <NavLink to="/forms/services">
                      <Button color="primary" type="submit">
                        Add Product
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />

        {!Loading ? (
          <Table
            data={filter}
            handleSearch={handleSearch}
            DeleteLens={DeleteLens}
            histroy={histroy}
          />
        ) : (
          <Row
            style={{
              width: '100%',
              height: '60vh',
              marginLeft: 1,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner style={{ color: '#4ce1b6' }} />{' '}
          </Row>
        )}
      </Container>
    </>
  );
};

export default DataTable;
