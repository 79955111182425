/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useHistory } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import moment from 'moment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@material-ui/core';
import TablePagination from '@mui/material/TablePagination';
import useTablePaginator from '../hooks/useTablePaginator';

export default function BasicTable({ data = [], handleSearch }) {
  const history = useHistory();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [SelectedProduct, setSelectedProduct] = React.useState([]);
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePaginator();
  const handleOpen = (_data) => {
    setSelectedProduct(_data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Products Ordered By Customer
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>

                      <TableCell align="right">Product ID</TableCell>
                      <TableCell align="right">Product Name</TableCell>
                      <TableCell align="right">Product Images</TableCell>
                      <TableCell align="right">Color</TableCell>
                      <TableCell align="right">Coating</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {SelectedProduct.length > 0
                      && SelectedProduct.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="right">
                            {row.product
                              ? row.product._id
                              : ' Product Deleted '}
                          </TableCell>
                          <TableCell align="right">
                            {row.product
                              ? row.product.name
                              : ' Product Deleted '}
                          </TableCell>
                          <TableCell align="right">
                            {row.product.color[0].images.length > 0
                              ? row.product.color[0].images.map((img, i) => (
                                <div>
                                  <a href={img} target="_blank" rel="noopener noreferrer">{`Link${i + 1}`}</a>
                                </div>
                              ))
                              : ''}
                          </TableCell>
                          <TableCell align="right">{row.color}</TableCell>
                          <TableCell align="right">{row.Coating ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{row.price}</TableCell>
                          <TableCell align="right">{row.total}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </Box>
        </Modal>
      </div>
      <>
        <TableContainer component={Paper}>
          <TextField id="standard-basic" style={{ margin: '5px 20px' }} label="Search via Order Id" onChange={e => handleSearch(e.target.value)} variant="standard" />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Order Id</TableCell>
                <TableCell align="right">Address</TableCell>
                <TableCell align="right">Customer Email</TableCell>
                <TableCell align="right">Customer Name</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Products</TableCell>
                <TableCell align="right">Payment</TableCell>
                <TableCell align="right">Payment Token</TableCell>
                <TableCell align="right">Placed On</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0
              && data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.Order_id}
                    </TableCell>
                    <TableCell align="right">
                      {row.ShippingAddress
                        && row.ShippingAddress.length > 0 && (
                        <>
                          {
                            row.ShippingAddress[0].Address
                            + row.ShippingAddress[0].City
                            + row.ShippingAddress[0].Pincode }
                        </>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.User ? row.User.email : ''}
                    </TableCell>

                    <TableCell align="right">
                      {row.User ? row.User.firstName + row.User.LastName : ''}
                    </TableCell>
                    <TableCell align="right">{row.User ? row.User.phone
                      : row?.GuestUser ? row.GuestUser.phoneNumber : ''}
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={() => handleOpen(row.orderedLens)}>
                        Products
                      </Button>
                    </TableCell>
                    <TableCell align="right">{row.Amount}</TableCell>
                    <TableCell align="right">
                      {row.payment.length > 0 ? row.payment[0].token : 'InComplete'}
                    </TableCell>
                    <TableCell align="right">
                      {moment(row.Order_Placed_On).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained" onClick={() => history.push(`/forms/edit/order/${row._id}`)}>Update Status</Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    </>
  );
}
