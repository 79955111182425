import React, { useEffect } from "react";
import { Col, Container, Row, Card, CardBody, Spinner } from "reactstrap";
import { CSVLink } from 'react-csv';
import { useHistory } from "react-router";
import Table from "./JoinTeamsTable";
import api from "../../../api";

const joinTeamHeaders = [
  {
    label: 'ID', key: '_id',
  },
  {
    label: 'Name', key: 'name',
  },
  {
    label: 'City', key: 'city',
  },
  {
    label: 'Phone Number', key: 'phone_number',
  },
  {
    label: 'Email', key: 'email',
  },
  {
    label: 'Date', key: 'createdAt',
  },
];

const DataTable = () => {
  const histroy = useHistory();
  const [data, setData] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);

  const csvLink = {
    headers: joinTeamHeaders,
    data,
    filename: 'csvfile.csv',
  };

  useEffect(() => {
    setLoading(true);
    GetTeamData();
  }, []);

  const GetTeamData = () => {
    api.interceptors.request.use(function (config) {
      config.headers.Authorization =
        `Bearer ` + localStorage.getItem("admin-token-lens");

      return config;
    });
    api.get("admin/joinTeamsData").then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };
  const DeleteTeam = (id) => {
    setLoading(true)
    api.interceptors.request.use(function (config) {

        config.headers.Authorization = `Bearer ` + localStorage.getItem("admin-token-lens");

        return config;
    });
    api
        .get(`admin/team/delete/${id}`)
        .then((res) => {

            if (res.data.success) {
                setData([])
                GetTeamData()

            }
        });
}


  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="react-table__wrapper">
                  <h3 className="page-title">Join Teams Data</h3>
                  <CSVLink {...csvLink}>Export to CSV</CSVLink>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        {}

        {!Loading ? (
          <Table data={data} histroy={histroy} DeleteTeam={DeleteTeam} />
        ) : (
          <Row
            style={{
              width: "100%",
              height: "60vh",
              marginLeft: 1,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner style={{ color: "#4ce1b6" }} />{" "}
          </Row>
        )}
      </Container>
    </>
  );
};

export default DataTable;
