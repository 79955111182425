import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BannerForm from './components/BannerForm';


const FormValidation = props => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Client Review</h3>

      </Col>
    </Row>
    <Row>
      <BannerForm />
    </Row>
  </Container>
);

export default FormValidation;
