import React, { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import axios from "axios";
import { Alert, Button } from 'reactstrap';
import renderCheckBoxField from '../form/CheckBox';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import axios from '../../../containers/axios'

const LogInForm = ({
  handleSubmit, errorMessage, errorMsg, fieldUser, typeFieldUser, form,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loggedIn, seytLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const onChangeUsername = (e) => {
    setUsername({
      [e.target.name]: e.target.value,
    });
  }

  const onChangePassword = (e) => {
    setPassword({
      [e.target.name]: e.target.value,
    });
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    axios
      .post("login/", {
        username: username.username,
        password: password.password,
        admin:1
      })
      .then(async (res) => {
        console.log("RESPONSE", res);
        if (res.data.status === 1) {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("id", res.data.data.id);
          toast(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log("id",localStorage.getItem("id"));
          seytLoggedIn({
            loggedIn: true,
          });
          // console.log(props)
          // props.history.push("/users-list");
        } else if (res.data.status === 0) {
          toast(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          console.log("wrong password");
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }
  return (
    <>
      {loggedIn ? <Redirect to="/tables/subscriptions" /> : null}
      <Form method="POST" className="form login-form" onSubmit={handleLoginSubmit}>
        <Alert
          color="danger"
          isOpen={!!errorMessage || !!errorMsg}
        >
          {errorMessage}
          {errorMsg}
        </Alert>
        <div className="form__form-group">
          <span className="form__form-group-label">{fieldUser}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="username"
              component="input"
              type={typeFieldUser}
              placeholder={fieldUser}
              onChange={onChangeUsername}
              className="input-without-border-radius"
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              onChange={onChangePassword}
              className="input-without-border-radius"
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={showPasswordToggle}
            ><EyeIcon />
            </button>
            <div className="account__forgot-password">
              <NavLink to="/reset_password">Forgot a password?</NavLink>
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group form__form-group-field">
            <Field
              name={`remember_me-${form}`}
              component={renderCheckBoxField}
              label="Remember me"
            />
          </div>
        </div>
        <div className="account__btns">
          {
            form === 'modal_login'
              ? <Button className="account__btn" type="submit" color="primary">Sign In</Button>
              : (
                <Button className="account__btn btn btn-primary">
                  Sign In
                </Button>
              )
          }
        </div>
      </Form>
    </>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(reduxForm()(LogInForm));
