import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import EditBanner from "./components/EditBanner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import api from "../../../api";

const EditStore = (props) => {
  const location = useLocation();
  const [data, setData] = React.useState([]);
  useEffect(() => {
    api.interceptors.request.use(function (config) {
      config.headers.Authorization =
        `Bearer ` + localStorage.getItem("admin-token-lens");

      return config;
    });
  
  api
      .get(
        `admin/banner/get/${props.match.params.id}`
      )
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Edit Banner</h3>
        </Col>
      </Row>
      <Row
        style={{
          width: "100%",
          height: "60vh",
          flex: 1,
          marginLeft: 1,
          justifyContent: "center",
        }}
      >
        {data.length > 0 ? (
          <EditBanner data={data[0]} banner_id={props.match.params.id} />
        ) : (
          <Spinner style={{ color: "#4ce1b6" }} />
        )}{" "}
      </Row>
    </Container>
  );
};

export default EditStore;
